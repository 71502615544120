import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import { Container, Row, Col } from 'react-bootstrap';
import '../components/post.css';

const Post = ({ data }) => {
  const { title, content, image, date } = data.contentfulPost;
  return (
    <Layout>
      <SEO title={title} />
      <Container>
        <Row>
          <Col>
            <div className="post">
              <div className="post-header">
                <h1>{title}</h1>
                <p>{date}</p>
              </div>
              <div className="post-thumbnail">
                <img alt={title} src={image.fluid.src} className="post-image" />
              </div>
              <div className="post-content">
                <div dangerouslySetInnerHTML={{ __html: content.childContentfulRichText.html }}></div>
              </div>
              <Link to="/posts">View more posts</Link>
              <Link to="/">Back to Home</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Post;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      date
      content {
        childContentfulRichText {
          html
        }
      }
      image {
        fluid(maxWidth: 990) {
          src
        }
      } 
    }
  }
`;